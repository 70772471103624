import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const INSERT_SUCCESS_SUB_USER = "INSERT_SUCCESS_SUB_USER";
export const GET_SUCCESS_SUB_USER = "GET_SUCCESS_SUB_USER";
export const GET_SUCCESS_ROLE = "GET_SUCCESS_ROLE";

export function insertSubUserActions(userData) {
    const { owner_id, role_id, name, email, mobile, fileName, base64File, mobile_code, mobile_flags, company_working_hour_id, login_otp_authenticate_id, key, active_deactive, total_user } = userData || {};
    return dispatch => {
        const SubData = { owner_id, role_id, name, email, mobile, fileName, base64File, mobile_code, mobile_flags, company_working_hour_id, login_otp_authenticate_id, key, active_deactive, total_user }
        axios
            .post(`${base_url()}/api/v1/user`, SubData, { withCredentials: true, credentials: 'include' })
            .then(response => {
                if (response.data.status) {
                    dispatch({
                        type: INSERT_SUCCESS_SUB_USER,
                        data: response,
                    });
                    userData.setOpen();
                    userData.formik();
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error(error.response?.data.message)
            }).finally(() => {
                userData.setSubmitting()
            })
    }
}

const getSubUser = (subUserArr) => ({
    type: GET_SUCCESS_SUB_USER,
    payload: subUserArr,
});

export function getSubUserActions() {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/user/list`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                if (response.status === 204) {
                    dispatch(getSubUser({ status: false, message: [] }))
                } else {
                    dispatch(getSubUser(response.data))
                }
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getSubUser({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}

// role
const getRole = (roleArr) => ({
    type: GET_SUCCESS_ROLE,
    payload: roleArr,
});

export function getRoleActions() {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/role`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                dispatch(getRole(response.status === 204 ? [] : response.data.message));
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getRole([]))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}