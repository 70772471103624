import React, { memo } from 'react'

const MySingleSelect = ({ name, label, value, onChange, error, helperText, disabled, showStar=false, options, valueKey, labelKey, labelKey2, flag, showError, titleKey = false }) => {
    return <>
        {flag !== 1 && <label>{label}{showStar && <span className="text-danger">*</span>}</label>}
        <select name={name} value={value} onChange={onChange} disabled={disabled || false} className={`form-control ${error ? 'is-invalid' : 'mb-1'}`}>
            <option value="">Choose</option>
            {Array.isArray(options) && options.map((e, i) => (<option key={i} value={e[valueKey]} title={titleKey ? (e[titleKey]) : e[labelKey]}>{e[labelKey]} {labelKey2 ? (e[labelKey2]) : ""}</option>))}
        </select>
        {flag !== 1 && <small> {error && (<div className="text-danger">{helperText}</div>)}</small>}
        {showError && <small> {error && (<div className="text-danger">{helperText}</div>)}</small>}
    </>
}

export default memo(MySingleSelect);