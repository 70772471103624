import React, { lazy, memo, forwardRef } from 'react';
import { Row, Col, Form } from "../../../base";
import InputGroup from 'react-bootstrap/InputGroup';
const FlagCode = lazy(() => import("./FlagCode"))

const MyInput = forwardRef(({ label, placeholder, error, helperText, showStar, flags = false, formik, ...props }, ref) => {

    return (
        <Col md={6} sm={12}>
            <Form.Group>
                <Row className="d-flex justify-content-between">
                    <Col md="4">
                        <Form.Label>
                            {label}{showStar ? <span className="text-danger">*</span> : null}
                        </Form.Label>
                    </Col>
                    <Col md="8" className={"mb-2"}>
                        <InputGroup>
                            {flags && <FlagCode formik={formik} name={props.name} flags={flags} />}
                            <Form.Control
                                {...props}
                                isInvalid={error}
                                placeholder={placeholder || label}
                                ref={ref}
                                style={{ flex: 1 }} // Ensures the input takes the remaining space
                            />
                            {helperText && <Form.Control.Feedback type="invalid">{helperText}</Form.Control.Feedback>}
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </Col>
    );
});
export default memo(MyInput);